import Hammer from 'hammerjs';
import './_animation.gsap';
import Clipboard from 'clipboard';
document.addEventListener("DOMContentLoaded", function() {
  document.querySelector('.menu').addEventListener('click', function () {
    document.querySelector('.nav-mobile').classList.add('show');
  });

  document.querySelector('.close').addEventListener('click', function () {
    document.querySelector('.nav-mobile').classList.remove('show');
  });

  document.querySelectorAll('.related-link, .article-link').forEach(function (elem) {
    elem.addEventListener('mouseenter', function () {
      this.parentNode.classList.add('hover');
    });
  })

  document.querySelectorAll('.related-link, .article-link').forEach(function (elem) {
    elem.addEventListener('mouseleave', function () {
      this.parentNode.classList.remove('hover');
    });
  })

  window.addEventListener('scroll', function () {
    if (document.querySelector('aside.share') !== null) {
      if (window.pageYOffset >= 715 && (window.pageYOffset - document.querySelector('.article').offsetTop) < (document.querySelector('.author').offsetTop + 715)) {
        document.querySelector('aside.share').classList.add('fixed');
      } else {
        document.querySelector('aside.share').classList.remove('fixed');
      }
    }
  });

  // image slider
  let slider = {};
  window.slider = slider;

  // 2. Settings
  slider.sliderPanelSelector = 'div.related-item';
  slider.sensitivity = 25;
  slider.activeSlide = 0;
  slider.slideCount = 0;

  slider.init = function (selector) {
    slider.sliderEl = document.querySelector(selector);
    slider.slideCount = slider.sliderEl.querySelectorAll(slider.sliderPanelSelector).length;
    var sliderManager = new Hammer.Manager(slider.sliderEl);
    sliderManager.add(new Hammer.Pan({
      threshold: 0,
      pointers: 0
    }));
    var pos = 0;
    var xpos = 0;
    sliderManager.on('panstart', function () {
      if (getComputedStyle(slider.sliderEl).transform != 'none') {
        slider.sliderEl.style.transform = 'translateX(' + parseInt(getComputedStyle(slider.sliderEl).transform.split(',')[4]) + 'px)';
        xpos = parseInt(getComputedStyle(slider.sliderEl).transform.split(',')[4]);
        slider.sliderEl.style.transition = 'none';
      }
    });
    sliderManager.on('pan', function (e) {
      pos = xpos + e.deltaX;
      slider.sliderEl.style.transform = 'translateX(' + pos + 'px)';
    });
    sliderManager.on('panend', function (e) {
      slider.sliderEl.style.transition = 'transform 1s cubic-bezier(0,.99,1,1)';

      window.requestAnimationFrame(function() {
        pos = (pos + (pos > xpos ? Math.abs(e.velocityX * 300) : pos === xpos ? 0 : -Math.abs(e.velocityX * 300)  ));
        if (pos >= 0) {
          pos = 0;
        } else if (pos <  -(slider.sliderEl.clientWidth - slider.sliderEl.querySelector('*').clientWidth*2)) {
          pos = -(slider.sliderEl.clientWidth - slider.sliderEl.querySelector('*').clientWidth*2);
        }
        slider.sliderEl.style.transform = 'translateX(' + pos + 'px)';
        xpos = pos;
      });
    });
  };
  // script to blur and hide text when gallery slides over it
  if (document.querySelector('.slider')) {
    slider.init( '.slider' );
  }
  // copy link to clipboard
  if (document.querySelector('.share-links') !== null) {
    window.cp = new Clipboard('.copy-link', {
      text: function (trigger) {
        trigger.classList.add('copied');
        setTimeout(function () {
          trigger.classList.remove('copied');
        }, 500);
        return trigger.getAttribute('data-copy');
      }
    });
  }
});

document.querySelectorAll('form').forEach(function(elm) {
  elm.reset();
  elm.addEventListener('submit', function(e) {
    let cancel = true;
    this.querySelectorAll('input, select, textarea').forEach(function(elm) {
      if (!elm.checkValidity()) {
        cancel = false;
      } else {
        elm.parentNode.classList.remove('invalid');
      }
    });
    if (!cancel) {
      if (navigator.vibrate) {
        navigator.vibrate(1000);
      }
      e.stopPropagation();
      e.preventDefault();
    }
    return cancel;
  }, false);
});

document.querySelectorAll('input, select, textarea').forEach(function(elm) {
  elm.addEventListener('focus', function() {
    this.parentNode.classList.add('not-empty');
  }, false);
  elm.addEventListener('blur', function() {
    if (this.checkValidity()) {
      this.parentNode.classList.remove('invalid');
    }
  }, false);
  elm.addEventListener('invalid', function() {
    if (elm.type == 'checkbox') {
      this.parentNode.parentNode.classList.add('invalid');
    } else {
      this.parentNode.classList.add('invalid');
    }
  }, false);
});

document.querySelector('.back-to-top').addEventListener('click', function () {
  animateScroll(document.querySelectorAll('html, body'), document.querySelector('header').offsetTop, 1000, () => {});
});

document.querySelector('.close-button').addEventListener('click', function () {
  document.querySelector('.search-window').classList.remove('enabled');
});

if (document.querySelector('.accordion')) {
  document.querySelectorAll('.accordion__item').forEach(item => {
    item.querySelector('h3').addEventListener('click', () => {
      item.classList.toggle('open');
    })
  })
}

window.zoeken = function() {
  document.querySelector('.search-window').classList.add('enabled');
};

function animateScroll(elements, prop, duration, done) {
  elements.forEach(function (element) {
    let currentProp = 0;
    currentProp = element.scrollTop;

    let first = +new Date();
    let tick = function () {
      if ((new Date() - first) >= duration) {
        element.scrollTop = currentProp + (prop - currentProp);
      } else {
        element.scrollTop = currentProp + (((new Date() - first) / duration) * (prop - currentProp));
      }

      if ((new Date() - first) < duration) {
        element.animating[0].timeout = (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      } else {
        done();
        element.animating = [{}];
      }
    };
    element.animating = [{}];
    element.animating[0].duration = duration;
    element.animating[0].timefunc = (window.requestAnimationFrame ? 'r' : 't');
    tick();
  });
}
